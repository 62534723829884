export const MxShipping = ({
  width = 58,
  height = 42,
}: {
  width?: number;
  height?: number;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 58 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.1667 25.0625H1.5V2.52778C1.5 2.12259 1.66098 1.73399 1.9475 1.44747C2.23401 1.16096 2.62259 1 3.02778 1H36.6389C37.0441 1 37.4327 1.16096 37.7192 1.44747C38.0057 1.73399 38.1667 2.12259 38.1667 2.52778V25.0625Z"
      fill="#C41719"
    />
    <path
      d="M40.3743 33.6562H38.1667M38.1667 7.875H48.8611C49.09 7.87514 49.3159 7.92669 49.5221 8.02585C49.7284 8.12502 49.9097 8.26926 50.0527 8.44792L56.1638 16.0868C56.3811 16.3576 56.4997 16.6944 56.5 17.0417V32.159C56.5 32.5642 56.339 32.9528 56.0525 33.2393C55.766 33.5258 55.3774 33.6868 54.9722 33.6868H50.091M44.2778 7.90554V16.6903C44.2778 17.0955 44.4388 17.484 44.7253 17.7706C45.0118 18.0571 45.4004 18.218 45.8056 18.218H56.5M38.1667 25.0625H1.5V2.52778C1.5 2.12259 1.66098 1.73399 1.9475 1.44747C2.23401 1.16096 2.62259 1 3.02778 1H36.6389C37.0441 1 37.4327 1.16096 37.7192 1.44747C38.0057 1.73399 38.1667 2.12259 38.1667 2.52778V25.0625ZM38.1667 25.0625V33.6562H13.2333C12.8802 32.6495 12.223 31.7774 11.3527 31.1604C10.4824 30.5434 9.44185 30.212 8.375 30.212C7.30815 30.212 6.26763 30.5434 5.39731 31.1604C4.52698 31.7774 3.86984 32.6495 3.51668 33.6562H3.02778C2.62259 33.6562 2.23401 33.4953 1.9475 33.2088C1.66098 32.9223 1.5 32.5337 1.5 32.1285V25.0625H38.1667ZM13.5313 35.4055C13.5313 36.7731 12.988 38.0846 12.021 39.0516C11.054 40.0185 9.74252 40.5618 8.375 40.5618C7.00748 40.5618 5.69598 40.0185 4.729 39.0516C3.76201 38.0846 3.21875 36.7731 3.21875 35.4055C3.21504 34.8195 3.31594 34.2374 3.51668 33.6868C3.86984 32.6801 4.52698 31.808 5.39731 31.191C6.26763 30.5739 7.30815 30.2425 8.375 30.2425C9.44185 30.2425 10.4824 30.5739 11.3527 31.191C12.223 31.808 12.8802 32.6801 13.2333 33.6868C13.4341 34.2374 13.535 34.8195 13.5313 35.4055V35.4055ZM50.3889 35.4055C50.3914 36.6993 49.908 37.9469 49.0346 38.9014C48.1613 39.8559 46.9614 40.4478 45.6725 40.5599C44.3836 40.672 43.0995 40.2962 42.0744 39.5068C41.0493 38.7175 40.358 37.5721 40.137 36.2973C39.916 35.0226 40.1816 33.7113 40.8812 32.623C41.5808 31.5347 42.6636 30.7487 43.915 30.4205C45.1665 30.0923 46.4955 30.2458 47.6392 30.8506C48.7829 31.4555 49.6579 32.4676 50.091 33.6868C50.2872 34.2386 50.3879 34.8199 50.3889 35.4055V35.4055Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.12439 8.1875L6.86328 16.109H8.79592L10.9959 8.19515L9.12439 8.1875Z"
      fill="white"
    />
    <path
      d="M14.1346 8.20296L11.9499 10.6092L11.186 8.19531L10.3457 11.0217L10.9797 13.8481L13.3172 11.0293L14.1346 8.20296Z"
      fill="white"
    />
    <path
      d="M13.9522 16.1247L16.2057 8.20312H14.2654L12.0195 16.117L13.9522 16.1247Z"
      fill="white"
    />
    <path
      d="M18.237 16.1401L20.4294 16.1478L19.4516 11.9311L22.5224 8.22623L20.6815 8.21858L19.0391 10.2353L18.5731 8.21094H16.3961L17.3127 12.1755L11.3926 19.211H13.2641L17.7252 13.963L18.237 16.1401Z"
      fill="white"
    />
    <path
      d="M16.0678 16.584H15.7393L15.2275 18.4097H16.4116L16.4727 18.1423L15.6248 18.127L16.0678 16.584Z"
      fill="white"
    />
    <path
      d="M17.5034 17.9821L17.3507 18.112H16.9458L16.8541 17.9974L17.1368 17.0043L17.2819 16.8897H17.6791L17.786 17.0043L17.5034 17.9821ZM17.9083 16.5918H17.2208L16.8465 16.8668L16.4951 18.1272L16.7242 18.4251H17.3888L17.786 18.112L18.1375 16.8745L17.9083 16.5918Z"
      fill="white"
    />
    <path
      d="M18.9471 17.7376L18.886 18.0126L18.7408 18.1196L18.3589 18.1119L18.2749 17.9821L18.5422 17.0272L18.7027 16.8974H19.0922L19.161 17.0272L19.1075 17.2411L19.436 17.2488L19.5276 16.8592L19.3138 16.5918H18.6645L18.252 16.905L17.9082 18.0967L18.1603 18.4328H18.7943L19.1763 18.1272L19.2756 17.7453L18.9471 17.7376Z"
      fill="white"
    />
    <path
      d="M21.0548 16.5994H20.7035L19.8708 17.3328L20.0694 16.5994L19.7333 16.5918L19.2139 18.4328H19.5653L19.741 17.7988L20.0542 17.5237L20.2299 18.4404H20.5507L20.3215 17.264L21.0548 16.5994Z"
      fill="white"
    />
    <path
      d="M21.2157 17.5983H21.8268L21.8879 17.3539H21.2768L21.399 16.9032H22.2851L22.3691 16.5977H21.1851L20.6504 18.4386H21.8497L21.9337 18.1331H21.0705L21.2157 17.5983Z"
      fill="white"
    />
    <path
      d="M22.5905 17.3848L22.7204 16.9112H23.2015L23.2703 16.9952L23.2092 17.2779L23.0794 17.4001L22.5905 17.3848ZM23.4995 17.3848L23.637 16.8654L23.446 16.6133H22.4988L21.9717 18.4543H22.2925L22.5065 17.6904H22.7279L22.8502 18.4543H23.1787L23.0488 17.6904H23.1405L23.4995 17.3848Z"
      fill="white"
    />
  </svg>
);
